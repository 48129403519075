<template>
  <div class="blogs-box">
    <div class="table tablescroll">
      <el-table :data="data" v-loading="loading">
        <el-table-column label="IMAGE" width="170">
          <template slot-scope="props">
            <p class="agent_avatar">
              <img v-if="props.row.cover === '' || props.row.cover === null" src="../../../assets/img/20191220101919.png" alt/>
              <img v-else :src="props.row.cover" alt />
            </p>
          </template>
        </el-table-column>
        <el-table-column label="TITLE" prop="title" min-width="200">
          <template slot-scope="props">
            <span @click="edit(props.row.blog_id)">{{ props.row.title }}</span>
          </template>
        </el-table-column>
        <el-table-column label="AUTHOR" width="200">
          <template slot-scope="props">
            <span @click="edit(props.row.blog_id)">{{ props.row.author_name }}</span>
          </template>
        </el-table-column>

        <el-table-column label="TAGS" min-width="140">
          <template slot-scope="props">
            <span class="tags-box" @click="edit(props.row.blog_id)" v-for="(item, index) in props.row.tags" :key="index">{{ item.name }}</span>
          </template>
        </el-table-column>
        <el-table-column label="DATE" min-width="120">
          <template slot-scope="props">
            <span @click="edit(props.row.blog_id)">{{ props.row.publish_time | dateFormat }}</span>
          </template>
        </el-table-column>
        <el-table-column width="52">
          <template slot-scope="scope">
            <i class="icon-text el-icon-edit-outline" @click="edit(scope.row.blog_id)"></i>
          </template>
        </el-table-column>
        <el-table-column width="52">
          <template slot-scope="scope">
            <i class="icon-text el-icon-delete" @click="handleBlogDeletet(scope.row.blog_id)" ></i>
          </template> </el-table-column>
        <!-- <el-table-column width="52">
          <template slot-scope="scope">
            <i class="icon-text el-icon-copy-document"></i>
          </template>
        </el-table-column> -->
      </el-table>
    </div>
    <Page :total="total" :pageSize="10" :currentPage="currentPage" @number="pagesChanged" ></Page>
     <Del ref="del" @del="del" :title="title"></Del>
  </div>
</template>
<script>
import Page from "../../common/Paging";
import { apiDelBlog } from "../../../API/api"
import Del from "../../common/DelDialog"
export default {
  props: ["data", "total", "currentPage"],
  components: { Page, Del },
  data() {
    return {
      loading: false,
      id:"",
      title:"Are you sure to delete this blog?"
    };
  },
  methods: {
    pagesChanged(number) {
      this.$emit("number", number);
      //   this.getRestaurants();
    },
    del(){
      apiDelBlog(this.id).then((res) => {
      this.data.forEach((item,index) => {
        if(this.id===item.blog_id){
         this.data.splice(index,1);
         this.$refs.del.show = false;
         this.$message.success("Success")
        }
      });
      });
    },
    handleBlogDeletet(id) {
      this.id = id;
      this.$refs.del.show = true;
    },
    edit(id){
      this.$router.push({name: "edit_blog",params:{id:id}})
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../../css/table.scss";
.blogs-box {
  .table {
    .agent_avatar img {
      width: 150px;
      height: 150px;
      object-fit: cover;
      border-radius: 4px;
    }
    .tags-box {
      padding: 8px;
      display: inline-block;
      background-color: #e1e9ef;
      border-radius: 4px;
      margin: 0 5px 5px 0;
    }
    .icon-text {
      font-size: 20px;
      color: #678993;
    }
  }
}
</style>