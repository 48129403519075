<template>
  <div class="blog_list">
    <div class="row blog-top">
      <h3 class="blog-title">Blog</h3>
      <el-button class="primary-button" @click="createNewBlog">+ Add New Blog</el-button>
    </div>
    <div class="blog_table" v-loading="loading">
      <Blogs-Table :data="BlogData" :total="total" :currentPage="currentPage" @number="pagesChanged"></Blogs-Table>
    </div>
  </div>
</template>
<script>
import { apiGetBlogs } from "../../API/api";
import BlogsTable from "./component/BlogsTable";
export default {
  data() {
    return {
      BlogData: [],
      limit: 10,
      total: 1,
      currentPage: 1,
      loading: false,
    };
  },
  components: { BlogsTable },
  computed: {
    query() {
      return { limit: this.limit, offset: this.offset };
    },
    offset() {
      return (this.currentPage - 1) * this.limit;
    },
  },
  methods: {
    getBlogDataMethods() {
      this.loading = true;
      apiGetBlogs(this.query).then((res) => {
          this.$store.commit("setBlogFilter", this.query);
          this.BlogData = res.results;
          this.total = res.count;
        }).finally(() => {
          this.loading = false;
        });
    },
    pagesChanged(number) {
      this.currentPage = number;
      this.getBlogDataMethods();
    },
    createNewBlog() {
      this.$router.push({ name: "creat_blog" });
    },
  },
  mounted() {
    if (this.$store.state.blogFilter != null) {
      this.currentPage = this.$store.state.blogFilter.offset / 10 + 1;
    }
    this.getBlogDataMethods();
  },
};
</script>
<style lang="scss" scoped>
@import "../../css/button.scss";
.blog_table {
  background-color: #ffffff;
  padding: 10px 0px 0px 0px;
  border-radius: 10px;
}
.blog-top {
  margin-bottom: 10px;
  text-align: left;
  justify-content: space-between;
  .blog-title{
    font-size: 24px;
    color: #36425D;
    font-family: "Roboto-Bold", sans-serif;
  }
  .primary-button{
     font-family: "Roboto-Medium", sans-serif;
  }
}
</style>
